import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Container } from 'utilities';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
import ArabicIcon from 'components/header/images/arabic.svg';
import EnglishIcon from 'components/header/images/english.svg';

const WarpperContainer = styled.div`
  ${Container};
  @media screen and (max-width: 1025px) {
    height: 100%;
    ul {
      height: 100%;
    }
  }
`;
const SubHeader = styled.div`
  background-color: #163260;
  @media screen and (max-width: 1025px) {
    heigth: 40px;
  }
  ul {
    display: flex;
    margin: 0;
    padding: 10px 24px;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;

    li + li {
      margin-inline-start: 55px;
    }
    a {
      color: #fff;
      font-size: 14px;
      text-decoration: none;
      font-weight: 300;
    }
  }
`;

const LanguageIcon = styled.img`
  width: 25px;
  height: 15px;
  margin-inline-start: 8px;
`;

const SubHeaderWrapper = () => {
  const { language } = i18next;
  const { t } = useTranslation('header');
  const invertLang = () => {
    return language === 'ar' ? 'en' : 'ar';
  };
  const ChangeLanguageButton = styled.button`
    font-family: 'FreightSans Pro';
    font-size: 14px;
    font-weight: 500;
    background: transparent;
    border: 0;
    color: white;
    text-transform: uppercase;
    cursor: pointer;

    &:focus {
      border: 0;
      outline-color: transparent;
    }
  `;

  return (
    <SubHeader>
      <WarpperContainer>
        <ul>
          <li>
            <Link to="/faqs">{t('subHeader.faqs')}</Link>
          </li>
          <li>
            <Link to="/about">{t('subHeader.aboutJabalBus')}</Link>
          </li>
          <li>
            <ChangeLanguageButton
              to="#"
              onClick={() => {
                i18next.changeLanguage(invertLang());
              }}
            >
              {invertLang()}
              <Helmet htmlAttributes={{ lang: language }} />
            </ChangeLanguageButton>
            <LanguageIcon
              src={i18next.language === 'en' ? ArabicIcon : EnglishIcon}
            />
          </li>
        </ul>
      </WarpperContainer>
    </SubHeader>
  );
};

export default SubHeaderWrapper;
