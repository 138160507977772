import React, { Suspense, lazy } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from 'utilities';

const LoadingBar = styled.div`
  height: 3px;
  background: ${colors.secondaryColor};
  opacity: 0.4;
  position: fixed;
  top: 0;
  z-index: 5;
  width: 100%;
  animation: loader 800ms ease-in-out infinite;

  @keyframes loader {
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  }
`;
const LoadingContent = () => <LoadingBar></LoadingBar>;

const HomePage = lazy(() => import('./home'));
const NoResultsPage = lazy(() => import('./no-results'));
const SearchTripsFormPage = lazy(() => import('../components/filter-trip'));
const StationsPage = lazy(() => import('./stations'));
const TicketingPage = lazy(() => import('./ticketing'));
const CheckoutPage = lazy(() => import('./ticketing/checkout'));
const PaymentPage = lazy(() => import('./ticketing/payment'));
const SummaryPage = lazy(() => import('./ticketing/summary'));
const SeasonalDestinationsPage = lazy(() => import('./seasonal-destinations'));
const LoginPage = lazy(() => import('./auth/login'));
const RegisterPage = lazy(() => import('./auth/register'));
const MyTripsPage = lazy(() => import('./my-trips'));
const ForgotPasswordPage = lazy(() => import('./auth/forgot-password'));
const ChangePasswordPage = lazy(() => import('./profile/change-password'));
const ShareableTicketPage = lazy(() => import('./shareable-ticket'));
const UserProfilePage = lazy(() => import('./profile/index'));
const AboutPage = lazy(() => import('./about'));
const FAQsPage = lazy(() => import('./faqs'));
const NotificationsPage = lazy(() => import('./notifications'));
const BusClassesPage = lazy(() => import('./bus-classes'));
const OurServicesPage = lazy(() => import('./our-services'));
const TermsAndConditionsPage = lazy(() => import('./terms'));
const TrackTripPage = lazy(() => import('./track-trip'));

export const Home = props => (
  <Suspense fallback={<LoadingContent />}>
    <HomePage {...props} />
  </Suspense>
);

export const NoResults = props => (
  <Suspense fallback={<LoadingContent />}>
    <NoResultsPage {...props} />
  </Suspense>
);
export const SearchTripsForm = props => (
  <Suspense fallback={<LoadingContent />}>
    <SearchTripsFormPage {...props} />
  </Suspense>
);
export const Stations = props => (
  <Suspense fallback={<LoadingContent />}>
    <StationsPage {...props} />
  </Suspense>
);
export const Ticketing = props => (
  <Suspense fallback={<LoadingContent />}>
    <TicketingPage {...props} />
  </Suspense>
);
export const Checkout = props => (
  <Suspense fallback={<LoadingContent />}>
    <CheckoutPage {...props} />
  </Suspense>
);
export const Payment = props => (
  <Suspense fallback={<LoadingContent />}>
    <PaymentPage {...props} />
  </Suspense>
);
export const Summary = props => (
  <Suspense fallback={<LoadingContent />}>
    <SummaryPage {...props} />
  </Suspense>
);
export const SeasonalDestinations = props => (
  <Suspense fallback={<LoadingContent />}>
    <SeasonalDestinationsPage {...props} />
  </Suspense>
);
export const Login = props => (
  <Suspense fallback={<LoadingContent />}>
    <LoginPage {...props} />
  </Suspense>
);
export const Register = props => (
  <Suspense fallback={<LoadingContent />}>
    <RegisterPage {...props} />
  </Suspense>
);
export const MyTrips = props => (
  <Suspense fallback={<LoadingContent />}>
    <MyTripsPage {...props} />
  </Suspense>
);

export const ForgotPassword = props => (
  <Suspense fallback={<LoadingContent />}>
    <ForgotPasswordPage {...props} />
  </Suspense>
);

export const ChangePassword = props => (
  <Suspense fallback={<LoadingContent />}>
    <ChangePasswordPage {...props} />
  </Suspense>
);

export const ShareableTicket = props => (
  <Suspense fallback={<LoadingContent />}>
    <ShareableTicketPage {...props} />
  </Suspense>
);

export const UserProfile = props => (
  <Suspense fallback={<LoadingContent />}>
    <UserProfilePage {...props} />
  </Suspense>
);

export const About = props => (
  <Suspense fallback={<LoadingContent />}>
    <AboutPage {...props} />
  </Suspense>
);

export const FAQs = props => (
  <Suspense fallback={<LoadingContent />}>
    <FAQsPage {...props} />
  </Suspense>
);

export const Notifications = props => (
  <Suspense fallback={<LoadingContent />}>
    <NotificationsPage {...props} />
  </Suspense>
);

export const BusClasses = props => (
  <Suspense fallback={<LoadingContent />}>
    <BusClassesPage {...props} />
  </Suspense>
);

export const OurServices = props => (
  <Suspense fallback={<LoadingContent />}>
    <OurServicesPage {...props} />
  </Suspense>
);

export const TermsAndConditions = props => (
  <Suspense fallback={<LoadingContent />}>
    <TermsAndConditionsPage {...props} />
  </Suspense>
);

export const TrackTrip = props => (
  <Suspense fallback={<LoadingContent />}>
    <TrackTripPage {...props} />
  </Suspense>
);
