// This is the search trip form. It consists of a form and a submit handler.
// It is the search bar that appears in the middle of the home screen.
// It is used as well in the search results screem.
// The user uses this component to search for a trip.

import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Select, Spin, Checkbox } from 'antd';
import { DatePicker } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import i18next from 'i18next';
import 'moment/locale/ar';
import styled from '@emotion/styled/macro';
import { AllActiveLocations } from 'services';
import './index.css';
import DownArrow from 'components/header/images/arrow-down.svg';
import { Button } from 'components/button';
import { colors } from 'utilities/theme';

import { useTranslation } from 'react-i18next';
import { FirebaseAnalytics } from 'helperFunctions/FirebaseAnalytics';
import { useAuthDataContext } from 'utilities';
import City from './City';
import { FaAngleDown } from 'react-icons/fa6';
import { isMobile } from 'react-device-detect';
moment.locale('en');
const Search = styled.div`
  position: relative;
  font-family: ${props => props.font};
  margin: 0 auto;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1280px) {
    background-color: #fff;
    border-radius: 10px;
  }
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

const Title = styled.p`
  display: inline-block;
  font-weight: 500;
  color: #163260;
  font-size: 18px;
  margin-bottom: 0;
  margin-inline-start: 10px;
  font-weight: 600;
`;

const SubmitButtonWrapper = styled.div`
  button {
    width: 85%;
    display: block;
    margin: 0 auto;
    max-width: 350px;
  }
`;

const { Option, OptGroup } = Select;

const Field = styled.div`
  display: inline-block;
  min-width: 230px;
  min-height: 80px;
  padding: 5px 20px;
  position: relative;
  width: 25%;
  user-select: none;
  text-align: start;

  @media (max-width: 1025px) {
    width: 100%;
    margin: 0 auto;
  }

  .ant-form-item {
    margin-bottom: 0;
    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
    }
  }

  @media (min-width: 1280px) {
    & + div {
      &::before {
        content: '';
        height: 77px;
        border: 1px solid #163260;
        opacity: 0.1;
        position: absolute;
        top: 12px;
        ${props => props.start}: 0;
      }
    }
  }
  @media (max-width: 1280px) {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;

    & + div {
      margin-top: 20px;
    }
  }

  @media (max-width: 620px) {
    & + div {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 1025px) {
    .ant-col.ant-form-item-label {
      text-align: start;
    }
  }
`;

const SelectInput = styled.div`
  .ant-select-selection {
    color: #163260;
    font-size: 16px;
    line-height: 1.25;
    border: 0;
    background-color: inherit;
    width: 100%;
    padding: 2px 5px;

    &__rendered {
      margin: 0;
      padding: 2px 5px;
    }

    &__placeholder {
      text-align: start;
    }

    &-selected-value {
      float: ${props => props.start};
    }

    &::after {
      content: '';
      background: url(${DownArrow}) 50% 50% no-repeat;
    }
  }
  .ant-select {
    width: 100% !important;
  }

  .ant-select-arrow {
    color: ${colors.primaryColor};
    left: ${props => props.left};
    right: ${props => props.right};
  }
`;

const InputField = styled.div`
  input {
    color: #163260;
    line-height: 1.25;
    font-size: 16px;
    border: 0;
    width: 100%;
    padding: 2px 8px;
  }
  .ant-calendar-picker-icon {
    right: ${props => props.right};
    left: ${props => props.left};
    top: 11px;
    color: ${colors.primaryColor};
    height: 22px;
    width: 22px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .ant-calendar-picker {
    width: 100%;

    &-clear {
      display: none !important;
    }
  }
`;
const SelectContainer = styled.div`
  position: absolute;
  z-index: 3;
  min-width: 100%;
  border-radius: 10px;
  padding: 16px;
  background-color: white;
  border: 1px solid #d7d4d5;
  user-select: none;
  transform: translate3d(0,0,0);
  height: ${isMobile ? '250px' : '300px'};
  overflow-y: scroll;
`;
const LocationTitle = styled.div`
  font-size: 16px;
  color: ${props => (props.locationName !== '' ? '#163260' : '#bfbfbf')};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SelectTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #163260b8;
  margin-bottom: 5px;
`;

const SearchTrips = ({
  form: { getFieldDecorator, getFieldValue, validateFields, setFieldsValue },
  searchParams = {},
  children,
}) => {
  const { t } = useTranslation('filterTrip');
  const { customer } = useAuthDataContext();
  const { phone, email } = customer || {
    id: null,
  };
  const { language } = i18next;

  const history = useHistory();

  const { from, to, trip_date, round_trip_date, edit_ticket } = searchParams;
  const initialRoundTripState = round_trip_date ? true : false;
  const [roundTrip, handleRoundTrip] = useState(initialRoundTripState);
  const { loading, error, data } = useQuery(AllActiveLocations);
  const [showFromSelect, setShowFromSelect] = useState(false);
  const [showToSelect, setShowToSelect] = useState(false);
  const [locationName, setLocationName] = useState('');
  const [destinationName, setDestinationName] = useState('');
  const locationFromContainer = useRef();
  const locationToContainer = useRef();
  const selecFromtContainer = useRef();
  const selectToContainer = useRef();

  const nameLang = `name_${language}`;
  // TODO: handle error in a better way
  if (error) return `Error! ${error.message}`;

  const tripDate =
    getFieldValue('trip_date') || (trip_date ? moment(trip_date) : undefined);

  const fromLocation = {
    city: '',
    station: '',
  };
  const toLocation = {
    city: '',
    station: '',
  };

  const fullTripDate = trip_date && moment(trip_date).locale(language);
  const fullRoundTripDate =
    round_trip_date && moment(round_trip_date).locale(language);

  const { activeLocations } = data || { activeLocations: [] };
  const availableLocations = activeLocations.reduce((result, location) => {
    const cityName = location.city[nameLang];
    const cityId = location.city.id;
    if (from === location.id) {
      fromLocation.city = cityName;
      fromLocation.station = location[nameLang];
    }

    if (to === location.id) {
      toLocation.city = cityName;
      toLocation.station = location[nameLang];
    }

    if (!Object.keys(result).includes(cityName)) {
      result[cityName] = [{ ...location, city_id: cityId }];
    } else {
      result[cityName].push({ ...location, city_id: cityId });
    }
    return result;
  }, {});

  const fromCityId = getFieldValue('from');
  let fromCityName;
  Object.keys(availableLocations).forEach(cityName => {
    availableLocations[cityName].forEach(location => {
      if (location.id === fromCityId) {
        fromCityName = cityName;
        return;
      }
    });
  });

  const handleSearchTrips = e => {
    e.preventDefault();
    const formFields = ['from', 'to', 'trip_date'];
    if (roundTrip) formFields.push('round_trip_date');
    validateFields(formFields, (err, fieldsValue) => {
      if (!err) {
        // setFormValues(fieldsValue);
        const { from, to, trip_date, round_trip_date } = fieldsValue;
        const tripDateFormatted = trip_date.format('YYYY-MM-DD');
        const roundTripDateFormatted =
          roundTrip && round_trip_date.format('YYYY-MM-DD');
        let edit_ticket_ = edit_ticket;
        if (edit_ticket == undefined) edit_ticket_ = '';
        const searchParams = roundTrip
          ? `/ticketing?from=${from}&to=${to}&trip_date=${tripDateFormatted}&round_trip_date=${roundTripDateFormatted}&edit_ticket=${edit_ticket_}`
          : `/ticketing?from=${from}&to=${to}&trip_date=${tripDateFormatted}&edit_ticket=${edit_ticket_}`;
        history.push(searchParams);
      } else {
        console.log(err);
      }
    });
  };

  const isIndex =
    history && history.location && history.location.pathname == '/';

  const childrenToRender =
    !loading &&
    children &&
    children(
      fromLocation,
      toLocation,
      fullTripDate.format('dddd  YYYY/MM/DD'),
      fullRoundTripDate && fullRoundTripDate.format('dddd  YYYY/MM/DD')
    );
  const findCity = location_id => {
    const entries = Object.entries(availableLocations);
    for (const [city, locations] of entries) {
      if (locations.find(location => location.id === location_id)) {
        return city;
      }
    }
  };

  const start = language === 'en' ? 'left' : 'right';
  const font = language === 'en' ? 'FreightSans Pro' : 'Cairo';
  const left = language === 'en' ? 'auto' : '10px';
  const right = language === 'en' ? '10px !important' : 'auto !important';
  const changeSelect = fromId => {
    const toId = getFieldValue('to');
    let from_location_ = data.activeLocations.find(
      location => location.id === fromId
    );
    let to_location_ = toId
      ? data.activeLocations.find(location => location.id === toId)
      : null;

    if (to_location_ && from_location_.city.id == to_location_.city.id) {
      setFieldsValue({ to: undefined });
      setDestinationName('');
    }
  };

  document.onclick = e => {
    if (
      e.target !== locationFromContainer.current &&
      !selecFromtContainer.current?.contains(e.target)
    ) {
      setShowFromSelect(false);
    }
    if (
      e.target !== locationToContainer.current &&
      !selectToContainer.current?.contains(e.target)
    ) {
      setShowToSelect(false);
    }
  };
  const handelFromShowselect = () => {
    setShowFromSelect(!showFromSelect);
  };
  const handelToShowselect = () => {
    setShowToSelect(!showToSelect);
  };
  const ComponentData = (
    <Form className="form-wrapper" onSubmit={handleSearchTrips}>
      <Search font={font}>
        <Field start={start}>
          <SelectInput start={start} left={left} right={right}>
            <Form.Item label={<Title>{t('fields.from')}</Title>}>
              {getFieldDecorator('from', {
                initialValue: from,
                rules: [
                  {
                    required: true,
                    message: t('validations.fieldValidation'),
                  },
                ],
              })(
                <div style={{position:'relative'}}>
                  <LocationTitle
                    locationName={locationName}
                    onClick={handelFromShowselect}
                    ref={locationFromContainer}
                  >
                    {locationName
                      ? locationName
                      : t('placeholders.toPlaceholder')}
                    <FaAngleDown color="#163260" />
                  </LocationTitle>
                  {showFromSelect && (
                    <SelectContainer
                      className="select-container"
                      ref={selecFromtContainer}
                      lang={language}
                    >
                      <SelectTitle>{t('placeholders.selecttitle')}</SelectTitle>
                      {Object.keys(availableLocations).map(
                        (cityName, cityIndex) => (
                          <City
                            city={cityName}
                            key={cityIndex}
                            availableLocations={availableLocations}
                            setFieldsValue={setFieldsValue}
                            handelShowselect={handelFromShowselect}
                            changeSelect={changeSelect}
                            setLocationName={setLocationName}
                            form={'from'}
                          />
                        )
                      )}
                    </SelectContainer>
                  )}
                </div>
              )}
            </Form.Item>
          </SelectInput>
        </Field>
        <Field start={start}>
          <SelectInput start={start} left={left} right={right}>
            <Form.Item label={<Title>{t('fields.to')}</Title>}>
              {getFieldDecorator('to', {
                initialValue: to,
                rules: [
                  {
                    required: true,
                    message: t('validations.fieldValidation'),
                  },
                ],
              })(
                <div style={{position:'relative'}}>
                  <LocationTitle
                    locationName={destinationName}
                    onClick={handelToShowselect}
                    ref={locationToContainer}
                  >
                    {destinationName
                      ? destinationName
                      : t('placeholders.toPlaceholder')}
                    <FaAngleDown color="#163260" />
                  </LocationTitle>
                  {showToSelect && (
                    <SelectContainer
                      className="select-container"
                      ref={selectToContainer}
                      lang={language}
                    >
                      <SelectTitle>{t('placeholders.selecttitle')}</SelectTitle>
                      {Object.keys(availableLocations)
                        .filter(cityName => cityName !== fromCityName)
                        .map((cityName, cityIndex) => (
                          <City
                            city={cityName}
                            key={cityIndex}
                            availableLocations={availableLocations}
                            setFieldsValue={setFieldsValue}
                            handelShowselect={handelToShowselect}
                            setDestinationName={setDestinationName}
                            form={'to'}
                          />
                        ))}
                    </SelectContainer>
                  )}
                </div>
                // <Select
                //   notFoundContent="loading ..."
                //   placeholder={t('placeholders.toPlaceholder')}
                // >
                //   {Object.keys(availableLocations)
                //     .filter(cityName => cityName !== fromCityName)
                //     .map((cityName, cityIndex) => (
                //       <OptGroup key={'cityKey' + cityIndex} label={cityName}>
                //         {availableLocations[cityName].map(
                //           (location, locationIndex) => (
                //             <Option
                //               key={'locationKey' + locationIndex}
                //               value={location.id}
                //               style={{
                //                 color: '#163260',
                //               }}
                //             >
                //               {location[nameLang]}
                //             </Option>
                //           )
                //         )}
                //       </OptGroup>
                //     ))}
                // </Select>
              )}
            </Form.Item>
          </SelectInput>
        </Field>
        <Field start={start}>
          <InputField left={left} right={right}>
            <Form.Item label={<Title>{t('fields.departureDate')}</Title>}>
              {getFieldDecorator('trip_date', {
                initialValue: tripDate,
                rules: [
                  {
                    type: 'object',
                    required: true,
                    message: t('validations.fieldValidation'),
                  },
                ],
              })(
                <DatePicker
                  style={{ width: '100%', border: 'none' }}
                  placeholder={t('placeholders.departureDatePlaceholder')}
                  disabledDate={current => {
                    return current && current < moment().startOf('day');
                  }}
                />
              )}
            </Form.Item>
          </InputField>
        </Field>
        {roundTrip && (
          <Field start={start}>
            <InputField left={left} right={right}>
              <Form.Item label={<Title>{t('fields.returnDate')}</Title>}>
                {getFieldDecorator('round_trip_date', {
                  initialValue: round_trip_date
                    ? moment(round_trip_date)
                    : undefined,
                  rules: [
                    {
                      validator: (rule, value, callback) => {
                        if (!tripDate)
                          callback(t('validations.returnDateValidation2'));
                        else if (!value)
                          callback(t('validations.fieldValidation'));
                        else if (value.valueOf() < tripDate.valueOf())
                          callback(t('validations.returnDateValidation1'));
                        else callback();
                      },
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: '100%', border: 'none' }}
                    placeholder={t('placeholders.returnDatePlaceholder')}
                    disabled={!tripDate}
                    disabledDate={endValue => {
                      const startValue = tripDate;
                      if (!endValue || !startValue) {
                        return false;
                      }
                      return endValue.valueOf() <= startValue.valueOf();
                    }}
                  />
                )}
              </Form.Item>
            </InputField>
          </Field>
        )}
        <Field start={start}>
          <SubmitButtonWrapper>
            <Form.Item>
              <Checkbox
                defaultChecked={round_trip_date ? true : false}
                onChange={e => handleRoundTrip(e.target.checked)}
              >
                {t('fields.roundTrip')}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                onClick={() => {
                  FirebaseAnalytics.logEvent('trip_search', {
                    source: `${window.location.href}`,
                    phoneNumber: `${phone}`,
                    email: `${email}`,
                    from: `${fromLocation.city} / ${fromLocation.station}`,
                    to: `${toLocation.city} / ${toLocation.station}`,
                  });
                }}
                primary
                htmlType="submit"
              >
                {isIndex
                  ? t('buttons.searchButton')
                  : t('buttons.changeSearchButton')}
              </Button>
            </Form.Item>
          </SubmitButtonWrapper>
        </Field>
      </Search>
    </Form>
  );

  return isIndex ? (
    <div>
      {ComponentData}
      {childrenToRender}
    </div>
  ) : (
    <Spin spinning={loading}>
      {ComponentData}
      {childrenToRender}
    </Spin>
  );
};

const SearchTripsForm = Form.create({})(SearchTrips);

export default SearchTripsForm;
