/* This is the cart component to view details of the selected seats to user during
the booking and checkout process. */

import React, {useEffect, useMemo} from 'react';
import styled from '@emotion/styled/macro';
import {colors} from 'utilities/theme';
import {CircleIcon, LocationIcon} from 'components/svgs';
import {Button} from 'components/button';
import {Input} from 'antd';
import {
    restructureTickets,
    useFormattedTime,
    calculateTicketsPrice,
} from 'utilities';
import {useHistory} from 'react-router-dom';

import {useTranslation} from 'react-i18next';
import i18next from 'i18next';
import Timer from '../svgs/components/timer';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import {isMobile} from 'react-device-detect';
import { useState } from 'react';

const CartContainer = styled.aside`
  text-align: center;
  flex: 1;
  background: white;
  margin-bottom: 40px;
  margin-inline-start: ${isMobile ? '0px' : '30px'};
  margin-top: 20px;
  min-height: 220px;
  box-shadow: 0 5px 14px 0 rgba(0, 45, 89, 0.04);
  border-radius: 10px;
  @media screen and (max-width: 1025px) {
    margin: 40px 0;
  }
`;

const CartTitle = styled.h2`
  padding: 26px;
  background: ${colors.primaryColor};
  color: white;
  border-radius: 10px;
`;

const CartTrips = styled.section`
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    background-image: linear-gradient(90deg,
    #000,
    #000 75%,
    transparent 75%,
    transparent 100%);
    background-size: 7px 1px;
    border: none;
  }
`;

const CartTrip = styled.dl`
  padding: 30px;
  font-size: 14px;
  color: ${colors.primaryColor};

  &:not(:last-child) {
    border-bottom: 1px solid black;
  }

  dt,
  dd {
    margin: 0;
    display: block;
    text-align: start;
  }
`;

const TripElement = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  .countDown {
    padding: 10px;
    color: green;
    font-size: 16px;
    font-weight: bold;
  }
`;

const TripPrice = styled(TripElement)`
  dt,
  dd {
    font-weight: bolder;
    font-size: 16px;
  }
`;

const TripActions = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    font-size: 12px;
    margin: 0;
  }
`;

const CartFooter = styled.section`
  padding: 30px;
  position: relative;

  button {
    width: 100%;
    margin-top: 20px;
  }

  &::before,
  ::after {
    content: '';
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: #f6f7f9;
    position: absolute;
    top: 0;
  }

  &::before {
    left: 0;
    transform: translateY(-50%) translateX(-50%);
  }

  &::after {
    right: 0;
    transform: translateY(-50%) translateX(50%);
  }
`;

const TotalPrice = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  h3 {
    font-size: 18px;
    font-weight: bolder;
  }
`;

const EmptyCart = styled.section`
  padding-top: 30px;

  h3 {
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 16px;
  }
`;

const GroupPriceContainer = styled.div`
  display: flex;
  flex-direction: column;

  dd:first-of-type {
    text-decoration: line-through;
    opacity: 0.6;
  }

  dd:last-of-type {
    color: ${colors.success};
  }
`;

const TotalPriceContainer = styled.div`
  display: flex;
  flex-direction: column;

  h3:first-of-type {
    text-decoration: line-through;
    opacity: 0.6;
  }

  h3:last-of-type {
    color: ${colors.success};
  }
`;
const DiscountForm = styled.form`
  display: flex;
  align-items: center;
  .ant-input {
    width: 200px;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    margin-bottom: 4px;
  }

  p {
    display: inline-block;
    margin-inline-end: 20px;
    color: ${colors.primaryColor};
    font-size: 18px;
    font-weight: bold;
  }
  button {
    min-width: auto;
    max-width: 100px;
    margin-top: 0;
    font-size: 14px;
    line-height: 36px;
    padding: 0 30px;
    border: 1px solid #0BCA47;
    border-radius: 50px;
    cursor: pointer;
    background-color: #0BCA47;
    color: #fff;
    display: inline-block;
    text-align: center;
  }
`;


const CartFormattedTime = ({time}) => {
    const {time: formattedTime} = useFormattedTime(time);
    return <dd>{formattedTime}</dd>;
};

const Cart = ({
                  cart,
                  checkout,
                  handleBusSalonModal,
                  handleSetCurrentTrip,
                  setRouteLineId,
                  setGroupTicketId,
                  setTicketToDelete,
                  getReservedSeats,
                  deductionAmount,
                  editTicket,
                  editTicketPrice,
                  getCartData,

              }) => {

    const { t } = useTranslation('cart');
    const { language } = i18next;
    const { Search } = Input;
    const history = useHistory();


    const setTrip = (groupedTickets, groupId) => {
        const trip = groupedTickets[groupId].tripDetails;
        const {routeLineId} = groupedTickets[groupId];
        setRouteLineId(routeLineId);
        setGroupTicketId(groupId);
        handleSetCurrentTrip(trip);
    };

    const {tickets} = cart || {tickets: []};

    const groupedTickets = useMemo(() => restructureTickets(tickets), [tickets]);

    const deduction = editTicket ? editTicketPrice - deductionAmount : 0;

    // This is used to calculate the current price
    // In case of promo code, the current price will be different from the original price.
    // In other cases, the current price will be the same as the original price.
    const totalPrice =
        useMemo(() => calculateTicketsPrice(groupedTickets, 'groupPrice'), [
            groupedTickets,
        ]) - deduction;

    // This is used to calculate the original price
    const originalTotalPrice =
        useMemo(() => calculateTicketsPrice(groupedTickets, 'originalGroupPrice'), [
            groupedTickets,
        ]) - deduction;

    function updateCart() {
        // getCartData() need more to work
        window.location.reload();
    }
    const myPrice= calculateTicketsPrice(groupedTickets, 'originalGroupPrice');
    const [priceAfterDiscount, setpriceAfterDiscount] = useState();
    const [discount, setDiscount] = useState(0);
    const handelChange = e => setDiscount(e.target.value);
    const addDiscount = () => {
      setpriceAfterDiscount(totalPrice - ((Number(discount)*totalPrice/100)));
      setChangingPrice(true)
    };
    useEffect(()=> setpriceAfterDiscount(myPrice),[]);
    const[changingPrice, setChangingPrice]= useState(false);
    console.log(changingPrice)
    
    const isPromocodeApplied = totalPrice !== originalTotalPrice;
    const isDiscountApplied = priceAfterDiscount > 0;
    console.log(myPrice);
    console.log(totalPrice);
    console.log((priceAfterDiscount));
    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            updateCart();
            return false;
        } else {
            // Render a countdown
            return (
                <span className={'countDown'}>
          {minutes}:{seconds}
        </span>
            );
        }
    };

    function getDate(remaining) {
        if (remaining)
            return Date.now() + 1000 * remaining;
    }

    return (
      <CartContainer>
        <CartTitle>{t('header')}</CartTitle>
        {cart && cart.tickets.length > 0 ? (
          <>
            <CartTrips>
              {Object.keys(groupedTickets).map((groupId, tripIndex) => (
                <CartTrip key={groupId}>
                  <TripElement>
                    <dt>
                      <CircleIcon />{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en' ? 'fromCityName' : 'fromCityNameAr'
                        ]
                      }{' '}
                      -{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en'
                            ? 'fromLocationName'
                            : 'fromLocationNameAr'
                        ]
                      }
                    </dt>

                    <CartFormattedTime
                      time={groupedTickets[groupId].fromLocationTime}
                    />
                  </TripElement>
                  <TripElement>
                    <dt>
                      <Timer color={'green'} height={'16px'} width={'20px'} />
                      {t('seatReserved')}
                      <Countdown
                        date={getDate(groupedTickets[groupId].remainingTime)}
                        renderer={renderer}
                      />
                      {t('minute')}
                    </dt>
                  </TripElement>
                  <TripElement>
                    <dt>
                      <LocationIcon />{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en' ? 'toCityName' : 'toCityNameAr'
                        ]
                      }{' '}
                      -{' '}
                      {
                        groupedTickets[groupId][
                          language === 'en'
                            ? 'toLocationName'
                            : 'toLocationNameAr'
                        ]
                      }
                    </dt>
                    <CartFormattedTime
                      time={groupedTickets[groupId].toLocationTime}
                    />
                  </TripElement>
                  <TripElement>
                    <dt>{t('tripDetails.departureDate')}</dt>
                    {groupedTickets[groupId].fromDate && (
                      <dd>
                        {groupedTickets[groupId].fromDate.replace(/-/g, '/')}
                      </dd>
                    )}
                    {!groupedTickets[groupId].fromDate && (
                      <dd>{groupedTickets[groupId].date.replace(/-/g, '/')}</dd>
                    )}
                  </TripElement>
                  {groupedTickets[groupId].seats.map(seat => (
                    <TripElement key={seat.seatNumber}>
                      <dt>
                        {t('tripDetails.seatNumber')} {seat.seatNumber}
                      </dt>
                      <dd>
                        {
                          seat[
                            language === 'en'
                              ? 'seatTypeNameEn'
                              : 'seatTypeName'
                          ]
                        }
                      </dd>
                    </TripElement>
                  ))}
                  <TripPrice>
                    <dt>{t('tripDetails.ticketPrice')}</dt>
                    {groupedTickets[groupId].originalGroupPrice &&
                    groupedTickets[groupId].originalGroupPrice - deduction !==
                      groupedTickets[groupId].groupPrice - deduction ? (
                      <GroupPriceContainer>
                        <dd>
                          {Math.max(
                            0,
                            (
                              groupedTickets[groupId].originalGroupPrice -
                              deduction
                            ).toFixed(2)
                          )}{' '}
                          {t('common:money.pound', {
                            count: Math.max(
                              0,
                              (
                                groupedTickets[groupId].originalGroupPrice -
                                deduction
                              ).toFixed(2)
                            ),
                          })}
                        </dd>{' '}
                        <dd>
                          {Math.max(
                            0,
                            groupedTickets[groupId].groupPrice.toFixed(2)
                          )}{' '}
                          {t('common:money.pound', {
                            count: Math.max(
                              0,
                              groupedTickets[groupId].groupPrice.toFixed(2)
                            ),
                          })}
                        </dd>
                      </GroupPriceContainer>
                    ) : (
                      <dd>
                        {Math.max(
                          0,
                          groupedTickets[groupId].groupPrice.toFixed(2)
                        )}{' '}
                        {t('common:money.pound', {
                          count: Math.max(
                            0,
                            groupedTickets[groupId].groupPrice.toFixed(2)
                          ),
                        })}
                      </dd>
                    )}
                  </TripPrice>
                  {!checkout && (
                    <TripActions>
                      <Button clean small></Button>
                      <Button
                        clean
                        small
                        onClick={() => {
                          setTicketToDelete(groupedTickets[groupId]);
                        }}
                      >
                        {t('buttons.deleteButton')}
                      </Button>
                    </TripActions>
                  )}
                </CartTrip>
              ))}
            </CartTrips>
            <CartFooter>
              <TotalPrice>
                {isPromocodeApplied ? (
                  <TotalPriceContainer>
                    <h3>{t('totalPrice')}</h3>
                    <h3>
                      {Math.max(0, originalTotalPrice.toFixed(2))}{' '}
                      {t('common:money.pound', {
                        count: Math.max(0, originalTotalPrice.toFixed(2)),
                      })}
                    </h3>{' '}
                    <h3>
                      {Math.max(0, totalPrice.toFixed(2))}{' '}
                      {t('common:money.pound', {
                        count: Math.max(0, totalPrice.toFixed(2)),
                      })}
                    </h3>
                  </TotalPriceContainer>
                ) : (
                  changingPrice ? (
                    <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h3>{t('السعر قبل الخصم')}</h3>
                      <h3 style={{ textDecorationLine: 'line-through' }}>
                        {totalPrice.toFixed(2)}{' '}
                        {t('common:money.pound', {
                          count: Math.max(0, totalPrice.toFixed(2)),
                        })}
                      </h3>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h3>{t('السعر بعد الخصم')}</h3>
                      <h3>
                        {priceAfterDiscount.toFixed(2)}{' '}
                        {t('common:money.pound', {
                          count: Math.max(0, totalPrice.toFixed(2)),
                        })}
                      </h3>
                    </div>
                      </>
                    ) : (
                      <>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h3>{t('totalPrice')}</h3>
                      <h3>
                        {totalPrice.toFixed(2)}{' '}
                        {t('common:money.pound', {
                          count: Math.max(0, totalPrice.toFixed(2)),
                        })}
                      </h3>
                    </div>
                  
                  </>
                    )
                )}
                  {/* <DiscountForm
                    onSubmit={e => e.preventDefault()}
                    value={discount}
                    onChange={handelChange}
                  >
                    <p>{t('حساب السعر بعد الخصم')}</p>
                    <Input type="text"  placeholder='ادخل النسبة المئوية للخصم%'/>
                    <Button onClick={() => addDiscount()}>ok</Button>
                  </DiscountForm> */}
              </TotalPrice>
              {!checkout && (
                <Button
                  success
                  onClick={() => {
                    if (editTicket) {
                      history.push({
                        pathname: `/checkout`,
                        state: {
                          editTicket: true,
                          ticketId: editTicket,
                          deductionAmount: deductionAmount,
                          editedCartToken: cart.token,
                          editTicketPrice: editTicketPrice,
                          totalPrice: totalPrice,
                        },
                      });
                    } else history.push('/checkout');
                  }}
                >
                  {t('buttons.bookNowButton')}
                </Button>
              )}
            </CartFooter>
          </>
        ) : (
          <EmptyCart>
            <h3>{t('emptyCart.sorryMessage')}</h3>
            <strong>{t('emptyCart.cartIsEmpty')}</strong>
          </EmptyCart>
        )}
      </CartContainer>
    );
};

export default Cart;
