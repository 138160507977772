export { default as login_ar } from './ar/login.json';
export { default as login_en } from './en/login.json';
export { default as css_ar } from './ar/css.json';
export { default as css_en } from './en/css.json';
export { default as header_ar } from './ar/header.json';
export { default as header_en } from './en/header.json';
export { default as filterTrip_ar } from './ar/filterTrip.json';
export { default as filterTrip_en } from './en/filterTrip.json';
export { default as home_ar } from './ar/home.json';
export { default as home_en } from './en/home.json';
export { default as footer_ar } from './ar/footer.json';
export { default as footer_en } from './en/footer.json';
export { default as faqs_ar } from './ar/faqs.json';
export { default as faqs_en } from './en/faqs.json';
export { default as destinations_ar } from './ar/destinations.json';
export { default as destinations_en } from './en/destinations.json';
export { default as stations_ar } from './ar/stations.json';
export { default as stations_en } from './en/stations.json';
export { default as profile_ar } from './ar/profile.json';
export { default as profile_en } from './en/profile.json';
export { default as myTrips_ar } from './ar/myTrips.json';
export { default as myTrips_en } from './en/myTrips.json';
export { default as cancelTrip_ar } from './ar/cancelTrip.json';
export { default as cancelTrip_en } from './en/cancelTrip.json';
export { default as cart_ar } from './ar/cart.json';
export { default as cart_en } from './en/cart.json';
export { default as complaint_ar } from './ar/complaint.json';
export { default as complaint_en } from './en/complaint.json';
export { default as about_ar } from './ar/about.json';
export { default as about_en } from './en/about.json';
export { default as busClasses_ar } from './ar/busClasses.json';
export { default as busClasses_en } from './en/busClasses.json';
export { default as common_ar } from './ar/common.json';
export { default as common_en } from './en/common.json';
export { default as contactUs_ar } from './ar/contactUs.json';
export { default as contactUs_en } from './en/contactUs.json';
export { default as privateBus_ar } from './ar/privateBus.json';
export { default as privateBus_en } from './en/privateBus.json';
export { default as ticket_ar } from './ar/ticket.json';
export { default as ticket_en } from './en/ticket.json';
export { default as forgotPassword_ar } from './ar/forgotPassword.json';
export { default as forgotPassword_en } from './en/forgotPassword.json';
export { default as register_ar } from './ar/register.json';
export { default as register_en } from './en/register.json';
export { default as verification_ar } from './ar/verification.json';
export { default as verification_en } from './en/verification.json';
export { default as noResults_ar } from './ar/noResults.json';
export { default as noResults_en } from './en/noResults.json';
export { default as notifications_ar } from './ar/notifications.json';
export { default as notifications_en } from './en/notifications.json';
export { default as ourServices_ar } from './ar/ourServices.json';
export { default as ourServices_en } from './en/ourServices.json';
export { default as paymentOptions_ar } from './ar/paymentOptions.json';
export { default as paymentOptions_en } from './en/paymentOptions.json';
export { default as busSalon_ar } from './ar/busSalon.json';
export { default as busSalon_en } from './en/busSalon.json';
export { default as searchResult_ar } from './ar/searchResult.json';
export { default as searchResult_en } from './en/searchResult.json';
export { default as tabPanel_ar } from './ar/tabPanel.json';
export { default as tabPanel_en } from './en/tabPanel.json';
export { default as summary_ar } from './ar/summary.json';
export { default as summary_en } from './en/summary.json';
export { default as trackTrip_ar } from './ar/trackTrip.json';
export { default as trackTrip_en } from './en/trackTrip.json';
export { default as termsConditions_ar } from './ar/termsConditions.json';
export { default as termsConditions_en } from './en/termsConditions.json';
export { default as mobileApp_ar } from './ar/mobileApp.json';
export { default as mobileApp_en } from './en/mobileApp.json';
export { default as deleteAccount_en } from './en/deleteAccount.json';
export { default as deleteAccount_ar } from './ar/deleteAccount.json';
