export { default as PaymentMethodsImg } from './payment-methods.jpg';
export { default as BestOptionImg } from './best-options-img.jpg';
export { default as BestOptionMobileImg } from './best-options-mobile-img.jpg';
export { default as MainSliderImg } from './main-slider-1.jpg';
export { default as AmanLogo } from './aman-logo.png';
export { default as FawryLogo } from './fawry-logo.png';
export { default as MasterCardLogo } from './master-card-logo.png';
export { default as VisaLogo } from './visa-logo.png';
export { default as VodafoneCashLogo } from './vcash.jpg';
export { default as Tickets } from './tickets.png';
export { default as MobileBackground } from './mobile-apps.png';
export { default as MobileBackgroundJb } from './mobile-app-jb.jpg';
export { default as AppStore } from './app-store.svg';
export { default as PlayStore } from './play-store.svg';
export { default as MobileBackgroundEnglish } from './mobile-background-english.png'
export { default as fawry } from './fawry.webp'
export { default as newAman } from './aman.jpeg'
export { default as  mastercardandvisa } from './mastercard-visa.png'
export { default as wallet } from './wallet.jpg'
export { default as Masary } from './Masary.jpg'
export { default as Sadad } from './Sadad.jpg'
export { default as payStore } from './pay-at-store.png'
export { default as smartWallet } from './smartwallet.png'
export { default as discountAdd } from './discountadd.jpg'
