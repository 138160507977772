import React from 'react';
import { useHistory } from 'react-router-dom';

import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyles from 'utilities/global-styles';
import styled from '@emotion/styled/macro';

const IndexMain = styled.main`
  @media screen and (max-width: 1280px) {
    margin: 0;
  }
`;

const PagesMain = styled.main``;
const Layout = ({ children }) => {
  const history = useHistory();
  const indexPage =
    history && history.location && history.location.pathname === '/';

  return (
    <>
      <GlobalStyles />
      <Header />
      {indexPage ? (
        <IndexMain>{children}</IndexMain>
      ) : (
        <PagesMain>{children}</PagesMain>
      )}
      <Footer />
    </>
  );
};

export default Layout;
