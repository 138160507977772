/* This is a static list of the terms and conditions. They are hard coded
into the system. Business didn't require them to be dynamic */

import React, { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { colors } from 'utilities';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';
import './styles.css';

const TermsAndConditionsContainer = styled.div`  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Header = styled.div`
  margin-top:  16px;
  font-size: ${isMobile ? '11px' : '16px'};
  h1 {
    color: ${props => props.color};
  }
`;
const Details = styled.div`
  h4 {
    color: #6c6c6c;
    font-size: ${i18next.language === 'en' ? '17px' : isMobile ? '12px' : '16px'};
  }
`;
const TermsAndConditions = styled.div`
  width: ${isMobile ? '80%' : '60%'};
`;
const ConditionContainer = styled.div`
  h2 {
    color: ${colors.primaryColor};
    margin-top: 32px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul li {
    margin: 8px 0 21px 0;
    color: #6c6c6c;
    font-size: ${i18next.language === 'en' ? '17px' :  isMobile ? '12px' : '16px'};
  }
`;

const ToS = () => {
  const { t } = useTranslation('termsConditions');
  useEffect(()=>{
    // remooooooove
    if(window.location.href.includes('en') && i18next.language === 'ar'){
      i18next.changeLanguage('en')
    };
    if(window.location.href.includes('ar') && i18next.language === 'en'){
      i18next.changeLanguage('ar')
    }

  }, [])
  return (
    <TermsAndConditionsContainer>
      <Header color={colors.primaryColor}>
        <h1> {t('title')}</h1>
      </Header>
      <TermsAndConditions>
        <Details>
          <h4>{t('details')}</h4>
        </Details>
        <ConditionContainer>
          <h2>{t('section1.title')}</h2>
          <ul>
            <li>{t('section1.policy1')}</li>
            <li>{t('section1.policy2')}</li>
            <li>{t('section1.policy3')}</li>
            <li>{t('section1.policy4')}</li>
            <li>{t('section1.policy5')}</li>
            <li>{t('section1.policy6')}</li>
            <li>{t('section1.policy7')}</li>
            <li>{t('section1.policy8')}</li>
          </ul>
        </ConditionContainer>

        <ConditionContainer>
          <h2>{t('section2.title')}</h2>
          <ul>
            <li>{t('section2.policy1')}</li>
            <li>{t('section2.policy2')}</li>
            <li>{t('section2.policy3')}</li>
            <li>{t('section2.policy4')}</li>
            <li>{t('section2.policy5')}</li>
            <li>{t('section2.policy6')}</li>
            <li>{t('section2.policy7')}</li>
            <li>{t('section2.policy8')}</li>
            <li>{t('section2.policy9')}</li>
          </ul>
        </ConditionContainer>

        <ConditionContainer>
          <h2>{t('section3.title')}</h2>
          <ul>
            <li>{t('section3.policy1')}</li>
            <li>{t('section3.policy2')}</li>
            <li>{t('section3.policy3')}</li>
            <li>{t('section3.policy4')}</li>
            <li>{t('section3.policy5')}</li>
            <li>{t('section3.policy6')}</li>
            <li>{t('section3.policy7')}</li>
          </ul>
        </ConditionContainer>

        <ConditionContainer>
          <h2>{t('section4.title')}</h2>
          <ul>
            <li>{t('section4.policy1')}</li>
            <li>{t('section4.policy2')}</li>
            <li>{t('section4.policy3')}</li>
            <li>{t('section4.policy4')}</li>
          </ul>
        </ConditionContainer>

        <ConditionContainer>
          <h2>{t('section5.title')}</h2>
          <ul>
            <li>{t('section5.policy1')}</li>
            <li>{t('section5.policy2')}</li>
            <li>{t('section5.policy3')}</li>
            <li>{t('section5.policy4')}</li>
            <li>{t('section5.policy5')}</li>
          </ul>
        </ConditionContainer>

        <ConditionContainer>
          <h2>{t('section6.title')}</h2>
          <ul>
            <li>{t('section6.policy1')}</li>
            <li>{t('section6.policy2')}</li>
          </ul>
        </ConditionContainer>

        <ConditionContainer>
          <h2>{t('section7.title')}</h2>
          <ul>
            <li>{t('section7.policy1')}</li>
            <li>{t('section7.policy2')}</li>
            <li>{t('section7.policy3')}</li>
            <li>{t('section7.policy4')}</li>
            <li>{t('section7.policy5')}</li>
            <li>{t('section7.policy6')}</li>
            <li>{t('section7.policy7')}</li>
            <li>{t('section7.policy8')}</li>
            <li>{t('section7.policy9')}</li>
          </ul>
        </ConditionContainer>

        <ConditionContainer>
       
          <h2>{t('section8.title')}</h2>
          <ul>
            <li>{t('section8.note')}</li>
            <li>{t('section8.policy1')}</li>
            <li>{t('section8.policy2')}</li>
            <li>{t('section8.policy3')}</li>
            <li>{t('section8.itemsList.item1')}</li>
            <li>{t('section8.itemsList.item2')}</li>
            <li>{t('section8.itemsList.item3')}</li>
            <li>{t('section8.itemsList.item4')}</li>
            <li>{t('section8.itemsList.item5')}</li>
            <li>{t('section8.itemsList.item6')}</li>
          </ul>
        </ConditionContainer>
        <ConditionContainer>
          <h2>{t('section10.title')}</h2>
          <ul>
            <li>{t('section10.policy1')}</li>
            <li>{t('section10.policy2')}</li>
            <li>{t('section10.policy3')}</li>
            <li>{t('section10.policy4')}</li>
            <li>{t('section10.policy5')}</li>
          </ul>
        </ConditionContainer>
        <ConditionContainer>
          <h2>{t('section9.title')}</h2>
          <ul>
            <li>{t('section9.policy1')}</li>
            <li>{t('section9.policy2')}</li>
          </ul>
        </ConditionContainer>
      </TermsAndConditions>
    </TermsAndConditionsContainer>
  );
};

export default ToS;
