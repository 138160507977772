import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { colors } from 'utilities';
import BusImage from '../scenes/auth/images/jabalBus.png';
import i18next from 'i18next';
import { isTablet } from 'react-device-detect';

const { language } = i18next;

const Container = css`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;
const ContainerFluid = css`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const FormContainer = css`
  background: #fff;
  padding: 40px;
  width: 90%;
  max-width: 730px;
  margin: 40px auto;
  border-radius: 10px;

  .ant-form .ant-row:nth-of-type(2) {
    margin-top: 40px;
  }

  .ant-form-item-label > label::after {
    content: none;
  }
  .ant-form-item-required::before {
    content: none;
  }
  .ant-input {
    border-radius: 0;
    &::placeholder {
      font-size: 12px;
    }
  }
  .ant-form-item-label {
    font-size: 16px;
    font-weight: 500;
    text-align: right;
  }
  .ant-form-item-label > label {
    color: ${colors.primaryColor};
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    margin-left: 24px;
  }

  .ant-select-selection__rendered {
    margin-right: 11px;
  }

  .ant-select-arrow {
    left: 11px;
    right: auto;
  }

  .ant-select-selection-selected-value {
    float: right;
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    text-align: right;
    font-size: 12px;
  }

  .ant-form-explain {
    margin-top: 8px;
  }

  textarea.ant-input {
    padding-right: 18px;
    padding-top: 12px;
  }
  textarea.ant-input::placeholder {
    font-size: 12px;
  }

  h1 {
    text-align: center;
    color: ${colors.primaryColor};
    font-size: 32px;
    font-weight: bolder;
    margin: 0;
    margin-bottom: 40px;
  }

  .ant-form-item-label > label::before {
    display: inline-block;
    margin-left: 4px;
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }

  .not_required {
    .ant-form-item-label > label::before {
      display: none;
    }
  }
`;

const ProfileFormContainer = styled.div`
  ${FormContainer};
  width: auto;
  margin: 0;
  max-width: inherit;
  align-self: start;
`;

const SideFormContainer = styled.div`
  ${Container};
  display: flex;

  section {
    ${FormContainer};
    margin: 40px 0;
    width: 50%;

    h1 {
      text-align: start;
    }
  }

  & > div {
    margin: 40px;
    flex: 1;
    background-image: url(${BusImage});
    background-repeat: no-repeat;
    background-position: ${isTablet ? 'center' : 'bottom'};
    background-size: 130%;
    background-position: 57% 40%;
  }

  @media screen and (max-width: 740px) {
    flex-direction: column;

    section {
      width: 100%;
      margin: 0;

      h1 {
        text-align: center;
      }
    }

    & > div {
      background: none;
    }
  }
`;

export {
  Container,
  ContainerFluid,
  FormContainer,
  SideFormContainer,
  ProfileFormContainer,
};
