import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';

const cache = new InMemoryCache();

const setAuthContext = async operation => {
  const token = localStorage.getItem('token');
  const headers = token ? { authorization: `Bearer ${token}` } : {};
  operation.setContext({
    headers,
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        // setting token in request headers if exists.
        .then(oper => setAuthContext(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          const { code: errorCode } = err.extensions;
          if (errorCode === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('cartToken');
            window.location.replace('/');
          }
          if (err.message === 'token expired') {
            // when AuthenticationError thrown in resolver

            // modify the operation context with a new token
            const oldHeaders = operation.getContext().headers;
            const oldToken = oldHeaders.authorization.split('Bearer ')[1];

            return new Observable(observer => {
              const refetchOptions = {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  authorization: `Bearer ${oldToken}`,
                },
                body: JSON.stringify({
                  query: `mutation { refreshToken { token } }`,
                }),
              };

              fetch(process.env.REACT_APP_BACKEND_URL, refetchOptions).then(
                refreshTokenResponse => {
                  if (refreshTokenResponse.ok) {
                    refreshTokenResponse
                      .json()
                      .then(refreshJSON => {
                        // Replacing old token with new token.
                        // No need to use SetContext since our requestLink relies on LocalStorage token
                        const newToken =
                          refreshJSON &&
                          refreshJSON.data &&
                          refreshJSON.data.refreshToken &&
                          refreshJSON.data.refreshToken.token;
                        if (newToken || newToken !== 'null') {
                          localStorage.setItem('token', newToken);
                        } else {
                          localStorage.removeItem('token');
                          window.location.replace('/');
                        }
                      })
                      .then(() => {
                        const subscriber = {
                          next: observer.next.bind(observer),
                          error: observer.error.bind(observer),
                          complete: observer.complete.bind(observer),
                        };

                        forward(operation).subscribe(subscriber);
                      })
                      .catch(error => {
                        console.log(error, 'error');
                        observer.error(error);
                        localStorage.removeItem('token');
                        localStorage.removeItem('cartToken');
                        window.location.replace('/');
                      });
                  }
                }
              );
            });
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }),
    requestLink,
    new HttpLink({
      uri: process.env.REACT_APP_BACKEND_URL,
    }),
  ]),
  cache,
  connectToDevTools: true,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default client;
