import React from 'react';
import styled from '@emotion/styled/macro';

import {
  FacebookLogo,
  InstagramLogo,
  PhoneLogo,
  TiktokLogo,
  WhatsApppLogo
} from 'components/svgs';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';

const ContactUs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 3 / 4;
  grid-row: 1 / 3;

  @media screen and (max-width: 820px) {
    order: 2;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;

    div:first-of-type {
      flex-basis: 40%;

      p {
        margin-inline-start: 0;
      }
    }
  }
`;
const CallUs = styled.div`
  display: flex;
  text-align: end;
  font-size: 25px;
  font-weight: bold;
  align-items: center;
  p {
    margin: 0 ${isMobile ? null : '10px'} ;
  }
  justify-content:center;
`;
const SocialMedia = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 12px;
  width:200px;
  align-self: center;

  @media screen and (max-width: 820px) {
    flex-grow: 1;
    margin-bottom: 0;
    align-items: center;
    justify-content: space-evenly;
  }
`;

const ContactUsFooter = () => {
  const { language } = i18next;
  return (
    <ContactUs>
      <a href="tel:0122 710 4444">
        <CallUs lang={language}>
          <PhoneLogo />
          <p>0122-710-4444</p>
        </CallUs>
      </a>
      <SocialMedia>
        <a href="https://www.facebook.com/JabalBusEgy" target="_blank">
          <FacebookLogo />
        </a>
        <a
          href="https://www.instagram.com/jabalbusegy"
          target="_blank"
        >
          <InstagramLogo />
        </a>
        <a href="https://www.tiktok.com/@jabalbusegy" target="_blank">
          <TiktokLogo />
        </a>
        <a href="https://wa.me/201227104444" target="_blank">
          <WhatsApppLogo />
        </a>
      </SocialMedia>
    </ContactUs>
  );
};

export default ContactUsFooter;
