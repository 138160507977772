// This is the private bus form. It consists of a form and a submit handler.

import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { notify } from 'utilities';
import { Input, Form, Select, Spin, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/ar';
import { Button } from 'components/button';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { requestPrivateBus, allBusTypes, AllActiveLocations } from 'services';
import { FormContainer } from 'utilities';
import enUS from 'antd/es/locale/en_US';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {Helmet} from 'react-helmet'

const { Option, OptGroup } = Select;

const PrivateBusContainer = styled.section`
  ${FormContainer}
  .ant-calendar-picker {
    width: 100%;
  }

  .ant-calendar-picker-icon {
    ${props => props.start}: auto;
    ${props => props.end}: 12px;
  }

  .ant-row {
    margin: 0 !important;
  }

  .ant-select-arrow {
    ${props => props.start}: auto;
    ${props => props.end}: 11px;
  }

  .ant-select-selection__placeholder {
    text-align: start;
  }

  .ant-select-selection-selected-value {
    float: ${props => props.start};
  }

  @media screen and (max-width: 1025px) {
    margin: 0;
    width: 100%;
    .ant-form-item-control {
      text-align: center;
    }
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(210px,1fr));
  grid-column-gap: 20px;
  grid-row-gap: 17px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 35px;
`;

const PrivateBusForm = ({
                          form: { getFieldDecorator, validateFields, getFieldValue },
                          location: { search },
                        }) => {
  const { t } = useTranslation('privateBus');
  const { language } = i18next;

  const [roundTrip, setRoundTrip] = useState(false);

  const history = useHistory();
  const { data } = useQuery(allBusTypes);

  const nameLang = `name_${language}`;

  const { data: activeLocationsData } = useQuery(AllActiveLocations);
  const { activeLocations } = activeLocationsData || { activeLocations: [] };
  const availableLocations =activeLocations.filter((item, index, self) => index === self.findIndex((obj) => (obj.city.id === item.city.id)))
  const $fromDate = getFieldValue('$fromDate');
  const fromCityId = getFieldValue('fromLocation');
  let fromCityName;

  availableLocations.forEach(city => {
    if (city.id === fromCityId) {
      fromCityName = city.city[nameLang];
      return;
    }
  });

  const [submitRequestPrivateBus, { loading: submitting, error }] = useMutation(
      requestPrivateBus
  );

  const handleSubmit = e => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        const {
          name,
          phone,
          busType,
          travelerNumber,
          fromCity,
          toCity,
          $fromDate,
        } = values;
        const fromDate = $fromDate.format('YYYY-MM-DD');
        const toDate = values.$toDate && values.$toDate.format('YYYY-MM-DD');

        submitRequestPrivateBus({
          variables: {
            name,
            phone,
            busType,
            travelerNumber,
            fromCity,
            toCity,
            fromDate,
            toDate: toDate && toDate,
          },
        })
            .then(res => {
              const {
                data: {
                  requestPrivateBus: { status, message_ar, message },
                },
              } = res;
              if (status) {
                notify('success', language === 'en' ? message : message_ar);
                history.push('/');
              } else {
                notify('error', language === 'en' ? message : message_ar);
              }
            })
            .catch(err => {
              notify('error', err && err.message);
            });
      }
    });
  };

  const start = language === 'en' ? 'left' : 'right';
  const end = language === 'en' ? 'right' : 'left';
  return (
      <PrivateBusContainer start={start} end={end}>
        <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
        >
          <title>Private Bus</title>
        </Helmet>
        <h1>{t('header')}</h1>
        <Form onSubmit={e => handleSubmit(e)}>
          <FormGrid locale={enUS}>
            <Form.Item label={t('fields.name')}>
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: t('validations.nameValidation'),
                  },
                ],
              })(<Input size="large" />)}
            </Form.Item>
            <Form.Item label={t('fields.phone')}>
              {getFieldDecorator('phone', {
                rules: [
                  {
                    required: true,
                    message: t('validations.phoneValidation'),
                    //pattern: new RegExp('^[0]{1}[0-9]{10}$'),
                  },
                ],
              })(<Input className="forced_ltr forced_english" size="large" />)}
            </Form.Item>

            <Form.Item label={t('fields.busType')}>
              {getFieldDecorator('busType', {
                rules: [
                  {
                    required: true,
                    message: t('validations.busTypeValidation'),
                  },
                ],
              })(
                  <Select
                      placeholder={t('placeholders.busTypePlaceholder')}
                      size="large"
                  >
                    {data &&
                        data.allBusTypes &&
                        data.allBusTypes.map((busType, busTypeIndex) => (
                            <Option key={'busType' + busTypeIndex} value={busType.id}>
                              {busType[language === 'en' ? 'name' : 'name_ar']}
                            </Option>
                        ))}
                  </Select>
              )}
            </Form.Item>
            <Form.Item label={t('fields.passengersN')}>
              {getFieldDecorator('travelerNumber', {
                rules: [
                  {
                    required: true,
                    message: t('validations.passengersNValidation'),
                    pattern: new RegExp('^[1-9]+[0-9]*$'),
                  },
                ],
              })(<Input className="forced_ltr forced_english" size="large" />)}
            </Form.Item>

            <Form.Item label={t('fields.from')}>
              {getFieldDecorator('fromCity', {
                rules: [
                  {
                    required: true,
                    message: t('validations.fromValidation'),
                  },
                ],
              })(
                  <Select
                      placeholder={t('placeholders.fromPlaceholder')}
                      size="large"
                  >
                    {availableLocations.map(
                        (city, cityIndex) => (
                            <Option
                                key={'city' + cityIndex}
                                value={city.city["id"]}
                            >
                              {city.city[nameLang]}
                            </Option>
                        )
                    )}
                  </Select>
              )}
            </Form.Item>
            <Form.Item label={t('fields.to')}>
              {getFieldDecorator('toCity', {
                rules: [
                  {
                    required: true,
                    message: t('validations.toValidation'),
                  },
                ],
              })(
                  <Select
                      placeholder={t('placeholders.toPlaceholder')}
                      disabled={!getFieldValue('fromCity')}
                      size="large"
                  >

                    {availableLocations
                        .filter(cityName => cityName.city.id !== getFieldValue('fromCity'))
                        .map(
                            (city, cityIndex) => (
                                <Option
                                    key={'cityKey' + cityIndex}
                                    value={city.city["id"]}
                                >
                                  {city.city[nameLang]}
                                </Option>
                            )
                        )}
                  </Select>
              )}
            </Form.Item>

            <Form.Item label={t('fields.departureDate')}>
              {getFieldDecorator('$fromDate', {
                rules: [
                  {
                    required: true,
                    message: t('validations.departureDateValidation'),
                  },
                ],
              })(
                  <DatePicker
                      size="large"
                      placeholder={t('placeholders.departureDatePlaceholder')}
                      disabledDate={current => {
                        return current && current < moment().startOf('day');
                      }}
                  />
              )}
            </Form.Item>

            <Form.Item style={{ top: '35%' }}>
              <Checkbox
                  defaultChecked={false}
                  onChange={e => setRoundTrip(e.target.checked)}
              >
                {t('fields.returnTrip')}
              </Checkbox>
            </Form.Item>

            {roundTrip && (
                <Form.Item label={t('fields.returnDate')}>
                  {getFieldDecorator('$toDate', {
                    rules: [
                      {
                        validator: (rule, value, callback) => {
                          if (!$fromDate)
                            callback(t('validations.returnDateValidation2'));
                          else if (!value)
                            callback(t('validations.returnDateValidation1'));
                          else if (value.valueOf() < $fromDate.valueOf())
                            callback(t('validations.returnDateValidation3'));
                          else callback();
                        },
                      },
                    ],
                  })(
                      <DatePicker
                          size="large"
                          placeholder={t('placeholders.departureDatePlaceholder')}
                          disabled={!$fromDate}
                          disabledDate={endValue => {
                            const startValue = $fromDate;
                            if (!endValue || !startValue) {
                              return false;
                            }
                            return endValue.valueOf() <= startValue.valueOf();
                          }}
                      />
                  )}
                </Form.Item>
            )}
          </FormGrid>
          <ButtonWrapper>
            <Form.Item>
              <Button primary disabled={submitting}>
                {submitting ? <Spin></Spin> : t('sendButton')}
              </Button>
            </Form.Item>
          </ButtonWrapper>
        </Form>
      </PrivateBusContainer>
  );
};

export default Form.create({})(PrivateBusForm);
