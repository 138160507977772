import React from 'react';
import styled from '@emotion/styled/macro';

import { InfoFooter, ContactUsFooter, LinksFooter } from './components';
import { Container, colors } from 'utilities';
import FooterBackGround from './images/footer.png';
import { BlueBusLogo } from 'components/svgs';
import { isMobile } from 'react-device-detect';
import ArabicLogo from './images/ArabicLogoWhite.svg';
import EnglishLogo from './images/EnglishLogoWhite.svg';
import i18next from 'i18next';

const FooterWrapper = styled.footer`
  background: ${colors.primaryColor} url(${FooterBackGround}) no-repeat bottom
    center;
  color: #fff;
  background-size: 100% 100%;
  a {
    color: #fff;
  }
`;
const WrapperContainer = styled.div`
  ${Container};
  margin: -26px auto;
  display: grid;
  padding:  ${isMobile ? '16px 32px' : '50px 30px 20px 24px'};
  grid-template-columns: '1fr 1fr 1fr';
  grid-template-rows: '1fr 1fr';
  grid-column-gap: 40px;
  text-align:center;

  svg {
    order: 0;
    grid-column: 1/2;
    grid-row: 1/2;
  }

  @media screen and (max-width: 920px) {
    grid-column-gap: 24px;
  }

  @media screen and (max-width: 820px) {
    display: flex;
    flex-direction: column;

    & > svg {
      margin-bottom: 20px;
    }
  }
`;
const InfoFooterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const LogoImage = styled.img`
    width: ${props => props.lang === "en" ? "200px" : "170px"};
    margin: 27px 9px;
    border-radius: 8px;
    padding: 8px 10px;
`
const Empty = styled.div``;

const LogoHolder = styled.div`
display:flex;
justify-content:${isMobile ? 'center' : 'start'};
`

const Footer = () => {
  const { language } = i18next;
  return (
    <>
      <FooterWrapper>
        <WrapperContainer>
          {language === 'ar' && (
           <LogoHolder>
             <LogoImage src={ArabicLogo} alt="ArabicLogo"  lang={language}/>
           </LogoHolder>
          )}
          {language === 'en' && (
            <LogoHolder>
             <LogoImage src={EnglishLogo} alt="ArabicLogo" lang={language} />
           </LogoHolder>
          )}
          <LinksFooter />
          <ContactUsFooter />
        </WrapperContainer>
        <InfoFooterWrapper>
          <InfoFooter />
        </InfoFooterWrapper>
      </FooterWrapper>
    </>
  );
};

export default Footer;
