import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import i18next from 'i18next';
import { FaAngleDown } from 'react-icons/fa6';
import { FaAngleUp } from 'react-icons/fa6';

const CityContainer = styled.div`
background-color: ${props => props.isActive ? "#163260" : "#f7f7f7" } ;
border-radius: 5px;
margin-bottom : 5px;
padding: 8px 13px;
}
`;
const CityTitle = styled.div`
  color: ${props => props.isActive ? "#fff" : "#163260" }  ;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StationContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 0px 10px;
  padding: 10px;
`;
const Station = styled.div`
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
  &:hover {
    background-color: #16326040;
  }
  p {
    margin: 0px 2px;
    font-weight: 600;
    font-size: 13px;
    color: #163260;
  }
`;

const City = ({
  city,
  availableLocations,
  setFieldsValue,
  handelShowselect,
  changeSelect,
  setLocationName,
  form,
  setDestinationName,
}) => {
  const { language } = i18next;
  const nameLang = `name_${language}`;
  const [show, setShow] = useState(false);
  const handelStationShow = () => {
    setShow(!show);
  };
  return (
    <CityContainer isActive={show}>
      <CityTitle onClick={handelStationShow} isActive={show}>
        {city}
        {show ?  <FaAngleUp/> : <FaAngleDown/>}
        
        </CityTitle>
      {show && (
        <StationContainer>
          {availableLocations[city].map((locatin, locationIndex) => (
            <Station
              key={'locationKey' + locationIndex}
              onClick={() => {
                if (form === 'from') {
                  changeSelect(locatin.id);
                  setFieldsValue({ from: locatin.id });
                  setLocationName(locatin[nameLang]);
                } else {
                  setFieldsValue({ to: locatin.id });
                  setDestinationName(locatin[nameLang]);
                }
                handelShowselect();
              }}
            >
              <p>{locatin[nameLang]}</p>
            </Station>
          ))}
        </StationContainer>
      )}
    </CityContainer>
  );
};

export default City;
