import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import App from './App';
import client from './apollo_client';
import * as serviceWorker from './serviceWorker';
import './index.css';
import ScrollToTop from './utilities/scrolltotop';
import { Helmet } from 'react-helmet';

// import 'antd-v4/dist/antd.css';
import 'antd/dist/antd.css';

// import i18n (needs to be bundled ;))
import './i18n';
import i18next from 'i18next';

const { language } = i18next;

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <ScrollToTop />
      <Helmet
        htmlAttributes={{
          lang: language,
        }}
      >
        <title>Jabal Bus</title>
        <meta name="description" content="Travel made easy with Jabal Bus" />
        <meta
          name="keywords"
          content="
        Jabal bus محور المشير
        ,
        حجز تذاكر اتوبيسات جبل باص
        ,
        جبل باص القاهرة
        ,
        حجز تذاكر جبل باص
        ,
        أسعار Jabal bus
        ,
        Jabal buss
        ,
        Jabal bus reservation
        ,
        موقع جبل باص
        ,
        Jabal bus prices
        ,
        bule bus
        ,
        Jabal bus alexandria
        ,
        مواعيد جبل باص من الاسكندرية الى القاهرة
        ,
        حجز Jabal bus
        ,
        اسعار جبل باص
        ,
        جبل بص
        ,
        سوبر جيت العين السخنة
        ,
        Jabal bus october
        ,
        رقم جبل باص اكتوبر
        ,
        فئات أتوبيسات جو باص
        ,
        جبل باص شرم الشيخ
        ,
        jabal bas
        ,
        جبل باص سوهاج
        ,
        Jabal bus حجز تذاكر
        ,
        Jabal bus الغردقة
        ,
        Jabal bus cairo
        ,
        Jabal bus dahab
        ,
        Jabal bus اسعار
        ,
        جبل باص حجز
        ,
        Jabal bus station
        ,
        jabal egypt
        ,
        Jabal bus رمسيس
        ,
        رقم جبل باص
        ,
        جبل باص العين السخنة
        ,
        jabalbus
        ,
        جبل باص اسكندرية
        ,
        Jabal bus schedule egypt
        ,
        شركة جبل باص
        ,
        جبل باص اكتوبر
        ,
        ارقام جبل باص
        ,
        Jabal bus number
        ,
        Jabal bus schedule
        ,
        Jabal bus hotline
        ,
        مواعيد جبل باص
        ,
        جبل باص الغردقة
        ,
        jabal bus
        ,
        Jabal bus مواعيد
        ,
        Jabal bus اكتوبر
        ,
        Jabal bus hurghada
        ,
        جبل باص
        ,
        Jabal bus booking
        ,
        حجز جبل باص
        ,
        Jabal bus حجز
        ,
        Jabal bus egypt
        ,
        jabal bus
        ,
        جبل باص
        ,
        bus
        ,
        اتوبيس
        "
        />
      </Helmet>{' '}
      <App />
    </BrowserRouter>{' '}
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
